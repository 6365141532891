import { graphql } from 'gatsby';
import { HeadInner } from '../../../components/Head';
import { CompleteLayout } from '../../../components/forms/CompleteLayout';
import { Layout } from '../../../components/layout';

export const Head: React.FC<{
  data: GatsbyTypes.DocumentsVideoSlugQueryQuery;
}> = (props) => {
  const slug = props.data.contentfulVideo?.slug;
  return (
    <HeadInner
      path={`/documents/${slug}/complete`}
      title='お申し込みありがとうございます。'
      noindex
    />
  );
};

const CompletePage: React.FC<{
  data: GatsbyTypes.DocumentsVideoSlugQueryQuery;
}> = (props) => {
  const title = props.data.contentfulVideo?.title;
  const slug = props.data.contentfulVideo?.slug;
  const links = [
    { name: 'お役立ち資料', path: '/documents/' },
    { name: title || 'お役立ち資料ダウンロード', path: `/documents/${slug}` },
    {
      name: 'お申し込みありがとうございます。',
      path: `/documents/${slug}/complete`,
    },
  ];

  return (
    <Layout
      header='simple'
      footer='simple'
      background='simple'
      isBottomMenu={false}
      links={links}
    >
      <CompleteLayout isSeminar>
        <h2>
          お申込み
          <br />
          ありがとうございます。
        </h2>
        <div css={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          <p>
            ご記入いただいたお問合せは送信完了しました。
            <br />
            確認のため、ご記入頂いたメールアドレスへ自動返信メールをお送りしております。
          </p>
          <p>
            しばらく経ってもメールが届かない場合は、
            <br />
            入力頂いたメールアドレスが間違っているか、迷惑メールフォルダに振り分けられている可能性がございますので、
            <br />
            お手数ですが、ご確認いただき再度ご連絡をお願いします。
          </p>
        </div>
      </CompleteLayout>
    </Layout>
  );
};

export const query = graphql`
  query DocumentsVideoSlugQuery($id: String) {
    contentfulVideo(id: { eq: $id }) {
      title
      slug
    }
  }
`;

export default CompletePage;
